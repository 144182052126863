import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

import { ROUTES } from './sidebar-routes.config';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from '../animations/custom-animations';
import { ConfigService } from '../services/config.service';
import { RoleService } from 'services/role.service';
import { UserSessionService } from 'services/usersession.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  animations: customAnimations
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @ViewChild('toggleIcon', { static: false }) toggleIcon: ElementRef;
  public menuItems: any[];
  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  nav_collapsed_open = false;
  logoUrl = 'assets/img/logos/nhmlogosmall.png';
  public config: any = {};


  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private roleService: RoleService,
    public translate: TranslateService,
    private configService: ConfigService,
    private usersession: UserSessionService
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }
  }

  ngOnInit() {
    // this.menuItems = ROUTES;
    // this.config = this.configService.templateConf;
    // this.menuItems = ROUTES;
    // this.roleService.getMenu(false).subscribe(result => {


    //   this.menuItems = result;
    // });

    if (this.config && this.config.layout && this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logos/nhmlogosmall.png';
    } else {
      this.logoUrl = 'assets/img/logos/nhmlogo.png';
    }
  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (this.config && this.config.layout) {
        if (this.config.layout.sidebar.collapsed !== undefined) {
          if (this.config.layout.sidebar.collapsed === true) {
            this.expanded = false;
            this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
            this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
            this.nav_collapsed_open = true;
          } else if (this.config.layout.sidebar.collapsed === false) {
            this.expanded = true;
            this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
            this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
            this.nav_collapsed_open = false;
          }
        }
      }
    }, 0);
  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf('forms/ngx') !== -1) {
      this.router.navigate(['forms/ngx/wizard'], { skipLocationChange: false });
    }
  }
}
