import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { UserSession } from '../models/usersession';

@Injectable()

export class CareerSessionService {

    session = new UserSession();
    localStorageSessionKey: string;

    constructor() {
        this.localStorageSessionKey = 'eGramam-' + environment.apiBaseUrl + '-CareerData';
    }

    create(session) {// jshint ignore:line
        this.setLocalStorageProperties(session);
    }

    destroy() {// jshint ignore:line
        this.setLocalStorageProperties(new UserSession());
        localStorage.removeItem('role');
    }

    load() { // jshint ignore:line
        const jsonData = localStorage.getItem(this.localStorageSessionKey);
        return jsonData;
    }

    getCarrersessionData() {
        const jsonData = localStorage.getItem(this.localStorageSessionKey);
        return jsonData == null ? '' : JSON.parse(jsonData);
    }

    

    setLocalStorageProperties(session: any) {// jshint ignore:line
        localStorage.setItem(this.localStorageSessionKey, JSON.stringify(session));
    }

    getLocalStorageWithKey(key: any) {// jshint ignore:line
        return localStorage.getItem(key);
    }

    setLocalStorageWithKey(key: any, session: any) {// jshint ignore:line
        localStorage.setItem(key, JSON.stringify(session));
    }

    

}

