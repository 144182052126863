
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { SevenSegModule } from 'ng-sevenseg';

import {
    PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { AuthGuard } from './shared/auth/auth-guard.service';
import { AuthenticationService } from 'services/authentication.service';
import { AlertService } from 'services/alert.service';
import { UserSessionService } from 'services/usersession.service';
import { CareerSessionService } from 'services/careersession.service';
import { DataService } from 'services/data.service';
import { HttpInterceptorService } from 'services/interceptor.service';
import { NavigationService } from 'services/navigation.service';
import { ToastrModule } from 'ngx-toastr';
import { SpinnerComponent } from './shared/component/spinnercomponent/spinner.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { UtilityService } from 'services/utility.service';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
};
import { AppSettings } from './app.settings';
import { MatSortModule, MatToolbarModule, MatTreeModule, MAT_RADIO_DEFAULT_OPTIONS, } from '@angular/material';
import { RoleService } from 'services/role.service';
import { UserService } from 'services/user.service';
import { DriverService } from 'services/driver.service';
import { NgxGaugeModule } from 'ngx-gauge';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LightboxModule } from 'ngx-lightbox';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
    //return new TranslateHttpLoader(http);
}
const DATEFNS_FORMATS_EN_LOCALE = {
    parseInput: 'dd/MM/yyyy HH:mm || dd/MM/yyyy HH:mm', // multiple date input types separated by ||
    fullPickerInput: 'dd/MM/yyyy HH:mm',
    datePickerInput: 'dd/MM/yyyy HH:mm',
    timePickerInput: 'HH:mm',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'dd/MM/yyyy HH:mm',
    monthYearA11yLabel: 'MMMM yyyy',
};
@NgModule({
    declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        MatSortModule,
        MatToolbarModule,
        MatTreeModule,
        HttpClientModule,
        FormsModule,
        LightboxModule,
        NgbModule.forRoot(),
        NgHttpLoaderModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        PerfectScrollbarModule,
        NgxGaugeModule,
        SevenSegModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        AuthGuard,
        AuthenticationService,
        AlertService,
        UserSessionService,
        CareerSessionService,
        NavigationService,
        DriverService,
        RoleService,
        UtilityService,
        UserService,
        DataService,
        AppSettings,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
    ],
    bootstrap: [AppComponent],
    entryComponents:
        [
            SpinnerComponent,
        ]
})
export class AppModule { }
