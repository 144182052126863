import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router,ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'services/authentication.service';
import { UserSessionService } from 'services/usersession.service';
import { NavigationService } from 'services/navigation.service';
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private navigation: NavigationService,
    private sessionService: UserSessionService) { 

      
     }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {



   const pageTitle=state.root.children[0]['firstChild']['children'][0]['data']['title'];
   const pageTitle2=state.root['firstChild']['firstChild']['firstChild']['data']['title'];
   // console.log(pageTitle,pageTitle2,state.url); 

    //if (this.authService.isPageAccessAvailable(state.url,pageTitle)) {
    //  return true;
    //}
    //if (!this.authService.isPageAccessAvailable(state.url,pageTitle)) {
    //  this.router.navigate(['dashboard']);
    //  return false;
    //}

    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    // return true;
  }

  hasRequiredPermission(permission) {

    for (let i = 0; i < permission.length; i++) {
      if (permission[i] === this.sessionService.roleId()) {
        return true;
      }
    }
    return false;
  }
}
