import { OnInit, Directive, Input, ElementRef, Renderer2, HostListener } from '@angular/core';
import { UserSessionService } from 'services/usersession.service';

@Directive({ selector: '[roleControl]' })
export class RoleDirective implements OnInit {

    @Input() roleControl: string;
    @Input() controlName: string;
    @Input() pageName: string;

    constructor(
        private elem: ElementRef,
        private renderer: Renderer2,
        private userSession: UserSessionService) { }

    ngOnInit() {
        this.renderer.removeClass(this.elem.nativeElement, 'showClass');
        this.renderer.addClass(this.elem.nativeElement, 'hiddenClass');
   //this.renderer.setStyle(this.elem.nativeElement, 'display', 'none !important');
        const sessionValue = this.userSession.getLocalStorageWithKey('role');
        if (sessionValue) {
            const roleData = JSON.parse(sessionValue);
            if (roleData) {
                for (let i = 0; i < roleData.length; i++) {
                    const result = this.checkPageRolePermission(roleData[i]);
                    if (result) {
                        return;
                    }
                }
            }
        }
    }

    checkPageRolePermission(page: any): Boolean { 
        if (page.title === this.pageName) {
            if (page.pageControl && page.pageControl.length > 0) {
                const pageControl = page.pageControl.filter(i => i.displayName === this.controlName);
                if (pageControl && pageControl.length > 0) {
                    this.renderer.removeClass(this.elem.nativeElement, 'hiddenClass');
                    this.renderer.addClass(this.elem.nativeElement, 'showClass');
                    //this.renderer.setStyle(this.elem.nativeElement, 'display', 'initial');
                    return true;
                }
            }
        } else if (page.submenu && page.submenu.length > 0) {
            for (let i = 0; i < page.submenu.length; i++) {
                const result = this.checkPageRolePermission(page.submenu[i]);
                if (result) {
                    return true;
                }
            }
        }
        return false;
    }
}
