import { Component, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LayoutService } from '../services/layout.service';
import { ConfigService } from '../services/config.service';
import { AuthenticationService } from 'services/authentication.service';
import { NavigationService } from 'services/navigation.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { UserSessionService } from 'services/usersession.service';
import { MatDialog } from '@angular/material';
import { Settings, AppSettings } from 'app/app.settings';

const DEFAULT_FONT_SIZE = 16;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit, AfterViewInit {
  currentLang = 'en';
  toggleClass = 'ft-maximize';
  placement = 'bottom-right';
  loggedOnPassword: boolean;
  loggedOnUserName: string;
  public settings: Settings;
  public valueChanged: boolean = false;
  languageList = [
    { key: 'en', value: 'English' },
    { key: 'af', value: 'தமிழ்' },
  ]

  fontSize: number;
  user_url: any;
  public isCollapsed = true;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  public config: any = {};
  public appliedTheme: any;
  public choosedLang: any;
  constructor(public translate: TranslateService,
    private layoutService: LayoutService,
    private authService: AuthenticationService,
    private navigationService: NavigationService,
    // private userSessionService: UserSessionService,
    private userSessionService: UserSessionService,
    private configService: ConfigService,
    public dialog: MatDialog,
    public appSettings: AppSettings,
  ) {

    this.fontSize = DEFAULT_FONT_SIZE;
    const logCheckdata = this.userSessionService.isDynamicPassword();
    this.loggedOnPassword = logCheckdata.isDynamicPassword;
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de|af/) ? browserLang : 'en');
    this.settings = this.appSettings.settings;
    this.appliedTheme = this.settings.theme;
    this.loggedOnUserName = userSessionService.getUserName();
  }

  ngOnInit() {
    this.config = this.configService.templateConf;

  }

  ngAfterViewInit() {
    if (this.config.layout.dir) {
      const dir = this.config.layout.dir;
      if (dir === 'rtl') {
        this.placement = 'bottom-left';
      } else if (dir === 'ltr') {
        this.placement = 'bottom-right';
      }
    }
  }

  ChangeLanguage(selectedLanguage) {
    
    this.translate.use(selectedLanguage);
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize';
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName('app-sidebar')[0];
    if (appSidebar.classList.contains('hide-sidebar')) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }
  clickProfile() {
    // this.navigationService.goToDepartments();
  }

  onLogout() {
    this.authService.logOut();
  }

  confirmtext() {
    swal.fire({
      title: 'Are you sure ?',
      text: 'You want to log out ',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.onLogout();
      }
    })
  }

  openMyProfile() {
    const myuserId = this.userSessionService.userId();
    this.user_url = '/user/' + myuserId;
    // this.navigationService.goToUser(myuserId);
  }


  public fontIncreaseTheme(theme) {
    
    if (this.settings.theme == 'blue') {
      this.settings.theme = theme;
    }
    else if (this.settings.theme == 'red') {
      this.settings.theme = 'purple';
    }
    else if (this.settings.theme == 'green') {
      this.settings.theme = theme;
    }
    else if (this.settings.theme == 'grey') {
      this.settings.theme = 'green';
    }

  }
  // decrease() {
  //   this.fontSize = (this.fontSize * 0.8);
  // }

  // increase() {
  //   this.fontSize = (this.fontSize * 1.2);
  // }

  // reset() {
  //   this.fontSize = DEFAULT_FONT_SIZE;
  // }

  chooseLanguage(event) {
    
    this.choosedLang = event.value;
    this.settings.langId = this.choosedLang;
    this.valueChanged = true;
    alert(this.settings.langId);
    localStorage.setItem('langId', this.choosedLang);

  }
}
