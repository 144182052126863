import { Injectable } from '@angular/core';

export class Settings {
    constructor(public name: string,
                public theme: string,
                public langId: number,
                ) { }
}

@Injectable()
export class AppSettings {
    public settings = new Settings(
        'HouseKey',  // theme name
        'blue',      // blue, green, red, pink, purple, grey, orange-dark
        1,           // 1 or 2   
    )
}