import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class UserService {

    getEventRoute = '/api/user/';

    constructor(private dataService: DataService) {
    };

    getState(refresh: boolean) {
        return this.dataService.getData('/api/district/statelist', refresh);
    }

    getDistrict(refresh: Boolean) {
        return this.dataService.getData('/api/district', refresh);
    }

    getDistrictfilterbyState(Id: number, refresh: Boolean) {
        return this.dataService.getData('/api/district/districtlist/' + Id, refresh);
    }

    getProofList(refresh: boolean) {
        return this.dataService.getData('/api/lookup/idprooftype', refresh);
    }

    getDegreeList(refresh: boolean) {
        return this.dataService.getData('/api/lookup/qualificationtype', refresh);
    }

    getgenderType(refresh: boolean) {
        return this.dataService.getData('/api/lookup/gendertype', refresh);
    }

    getSkillSet(refresh: boolean) {
        return this.dataService.getData('/api/lookup/skilltype', refresh);
    }

    save(result: any) {
        return this.dataService.post('/api/jobseeker/post', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }
}
