import { NgModule } from '@angular/core';
import { OnlyNumber } from "./onlynumber.directive";
import { SpecialCharacter } from "./onlychar.directive";
import { RoleDirective } from './role.directive';

export const components = [
  OnlyNumber,SpecialCharacter,RoleDirective
];

@NgModule({
  declarations: [components],
  exports: [components]
})
export class DirectivesModule {}
