// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    //apiBaseUrl: 'https://tnega.tn.gov.in/careersapi',
    apiBaseUrl: 'https://itvolunteeringapi.xenovex.com',
    careersBaseUrl: 'https://careersapi.xenovex.com',
   // apiBaseUrl: 'http://localhost:57171',
    projectName: 'jobapply',
    signalrUrl: 'http://localhost:5000/notifyhub',
    imageSliderurl: '/assets/data/slides.json',
    tokenEndPoint: '/api/token',
    production: false,
    environment: 'Local',
    showEnvironment: true,
    recaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
};
