import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class DriverService {

    route = '/api/driver';

    constructor(private dataService: DataService) {
    };

    get(refresh: Boolean) {
        return this.dataService.getData(this.route, refresh);
    }

    getLookUp(refresh: Boolean) {
        return this.dataService.getData(this.route + '/lookup', refresh);
    }

    getById(id: number) {
        return this.dataService.getRecord('/api/image/' + id);
    }

    save(data: any) {
        console.log(JSON.stringify(data));
        return this.dataService.post('/api/image', data).map(response => {
            return response;
        });
    }
    getGallery(id: number,type: number) {
        return this.dataService.getRecord(('/api/gallery/filetype?'+'id='+type+'&fileType='+id ));
   }
    // saveActiveStatus(activeState: any) {
    //     return this.dataService.post('/api/employee/activate', activeState);
    // }
}
