import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()

export class NavigationService {
    constructor(private router: Router) {

    }

  

    goToUnAuthorized() {
        this.router.navigate(['/unauthorized']);
    }



    goToVolunteerRegister(mobileno) {
        this.router.navigate(['/volunteerregister/' + mobileno]);
    }
}
