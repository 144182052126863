import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class RoleService {

    getEventRoute = '/api/role/';

    constructor(private dataService: DataService) {
    };

}
